<template lang="pug">
v-overlay(:value='Cargando' absolute opacity=1)
  v-container(align='center' justify='center')  
    v-row
      img(:src='cargando'  width='550px')

</template>
<script>
import { mapState, mapMutations } from 'vuex'
import cargando from '../../../assets/cargando.gif'
export default {
  data: ()=>({
    cargando,
  }),
  computed:{
    ...mapState({
      Cargando: ({Ayudas}) => Ayudas.cargando,
    }),
  },
  created(){
    this.alterar_cargando(true)
    setTimeout(()=>{
        this.alterar_cargando(false)
    },3000)
  },
  methods: {
      ...mapMutations({
          alterar_cargando: 'Ayudas/alterar_cargando',
      }),
  },
}
</script>